import React from 'react';
import Script from 'next/script';

import { useConsentManager } from '../consent-manager';

declare global {
  interface Window {
    ChiliPiper?: {
      submit: (accountName: string, routerName: string) => void;
    };
  }
}

/**
 * Load the ChiliPiper scheduling script after airgap.js is initialized
 */
export const ChiliPiperScript: React.FC = () => {
  const { airgap } = useConsentManager();
  return (
    <>
      {airgap && (
        <Script
          src="https://js.chilipiper.com/marketing.js"
          strategy="lazyOnload"
        />
      )}
    </>
  );
};
