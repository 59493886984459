import type { SanityRelatedContent } from '@/lib/sanity/queries/relatedContent';
import { Link } from '@/components/Link';

import { Headline } from './Headline';
import { Media } from './Media';

export function RelatedContent(props: SanityRelatedContent) {
  return (
    <div className="p-main my-100">
      <div className="mx-auto max-w-1235">
        <Headline value={props.headline} className="mb-40 text-center" />
        <div className="-mx-10 flex flex-wrap gap-y-40">
          {props.items.map((item) => (
            <div key={item._key} className="w-full px-10 m:w-1/2 l:w-1/3">
              <Link href={item.href} className="block">
                <Media
                  media={item.thumbnailImage}
                  className="aspect-[16/9] rounded-15 border-1 border-grayscale-03 object-cover m:rounded-25"
                  aspect={false}
                  sizes={{ m: '50vw', l: '33vw', '1285px': '412px' }}
                />
                <div className="mt-20">
                  <Headline
                    as="h3"
                    value={item.title}
                    variant={6}
                    className="leading-116"
                  />
                  <p className="mt-10 text-14 font-light leading-160 text-grayscale-06 l:text-16">
                    {item.excerpt}
                  </p>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
