import * as React from 'react';
import { useState } from 'react';
import {
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
  Combobox as HeadlessCombobox,
} from '@headlessui/react';
import { Check } from 'lucide-react';
import { ControllerRenderProps } from 'react-hook-form';

import { cn } from '@/lib/utils';
import { ChevronDown } from '@/components/ChevronDown';

export type ComboBoxFilter = (
  search: string,
  options: ComboBoxOptions,
) => ComboBoxOptions;

export type ComboBoxOptions = Array<{
  value: string;
  label: string;
  key?: string;
}>;

type Props = Partial<ControllerRenderProps> &
  React.InputHTMLAttributes<HTMLSelectElement> & {
    className?: string;
    options: ComboBoxOptions;
    filter?: ComboBoxFilter;
  };

const defaultFilter: ComboBoxFilter = (search, options) => {
  return search === ''
    ? options
    : options.filter((option) => {
        return option.label.toLowerCase().includes(search.toLowerCase());
      });
};

/**
/**
 * Combobox Component
 *
 * This component renders a customizable Combobox (select) with search functionality.
 * It uses a popover to display the list of options and allows filtering of options based on user input.
 *
 * Props:
 * - className (string): Additional class names to apply to the component.
 * - options (ComboBoxOptions): Array of options to display.
 * - filter (ComboBoxFilter): Function to filter the options based on user input. For most cases, the default filter is sufficient.
 * - placeholder (string): Placeholder text for the trigger button.
 * - onChange (function): Callback function to handle the change event when an option is selected. Generally, this is the onChange from react-hook-form.
 * - value (string): The currently selected value. Generally, this is the value from react-hook-form.
 * - autoComplete (string): The autoComplete attribute for the search input.
 *
 * Example usage:
 * <ComboBox
 *   placeholder="Company Headquarters"
 *   options={[
 *     { value: 'option1', label: 'Option 1' },
 *     { value: 'option2', label: 'Option 2' },
 *   ]}
 *   onChange={(value) => console.log(value)}
 *   {...rest}
 * />
 */

export const Combobox = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      placeholder,
      options,
      onChange,
      value,
      filter = defaultFilter,
      className,
      name,
      autoComplete,
    },
    ref,
  ) => {
    const [query, setQuery] = useState('');
    const filteredOptions = filter(query, options);

    return (
      <div className={cn('relative w-full', className)}>
        <HeadlessCombobox
          as="div"
          immediate
          value={value}
          onChange={onChange}
          onClose={() => setQuery('')}
          ref={ref}
        >
          <ComboboxButton className="absolute inset-0">
            <ChevronDown className="icon-indicator" />
          </ComboboxButton>
          <ComboboxInput
            placeholder={placeholder}
            displayValue={(value) =>
              options.find((option) => option.value === value)?.label ?? ''
            }
            className={cn('trigger', {
              'text-grayscale-04': !value && !query,
            })}
            aria-label={name}
            autoComplete={autoComplete}
            onChange={(event) => setQuery(event.target.value)}
          />
          <ComboboxOptions
            anchor={{
              to: 'bottom',
              gap: 6,
            }}
            transition
            className="w-[var(--input-width)] options-container"
          >
            {filteredOptions.map((option) => (
              <ComboboxOption
                key={option.value}
                value={option.value}
                className="group options-item"
              >
                <Check className="options-item-checked" />
                {option.label}
              </ComboboxOption>
            ))}
          </ComboboxOptions>
        </HeadlessCombobox>
      </div>
    );
  },
);

Combobox.displayName = 'Combobox';
