/**
 * Submission handler for ChiliPiper scheduling modal
 *
 * Unfortunately, ChiliPiper automagically reads the form fields from the DOM
 * @see https://help.chilipiper.com/hc/en-us/articles/360053800373-About-Concierge-Snippet-and-JS-API
 */
export function triggerSchedulingModal() {
  try {
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
      if (!window.ChiliPiper) {
        throw new Error('ChiliPiper was not loaded!');
      }
      window.ChiliPiper.submit('transcend', 'inbound-router');
    }
  } catch (err) {
    console.error(err);
  }
}
